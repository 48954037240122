import './Sidebar.css';

import React, { useEffect, useState } from 'react';
import {
  faBars,
  faTachometerAlt,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  const [activeLink, setActiveLink] = useState('');
  const [userData, setUserData] = useState(null);
  const [isSidebarVisible, setSidebarVisible] = useState(true); // Add state for sidebar visibility

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      setUserData(JSON.parse(userDataFromStorage));
    }
  }, []);

  const handleNavLinkClick = (link) => {
    setActiveLink(link);
  };

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible); // Toggle sidebar visibility
  };

  return (
    <div>
      {/* Add a toggle button for small screens */}
      <button
        className="btn btn-outline-secondary d-md-none"
        onClick={toggleSidebar}
      >
        <FontAwesomeIcon icon={faBars} />
      </button>

      {/* Sidebar component */}
      <div
        className={`sidebar bg-light ${
          isSidebarVisible ? '' : 'd-none d-md-block'
        }`}
      >
        <div className="content-left p-3">
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
              <NavLink
                to="/dashboard"
                className={`nav-link ${
                  activeLink === '/dashboard' ? 'active' : ''
                }`}
                onClick={() => handleNavLinkClick('/dashboard')}
              >
                <FontAwesomeIcon icon={faTachometerAlt} className="me-2" />
                Dashboard
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/social-events"
                className={`nav-link ${
                  activeLink === '/social-events' ? 'active' : ''
                }`}
                onClick={() => handleNavLinkClick('/social-events')}
              >
                <FontAwesomeIcon icon={faUsers} className="me-2" />
                Social Events
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/UpcomingEventDash"
                className={`nav-link ${
                  activeLink === '/UpcomingEventDash' ? 'active' : ''
                }`}
                onClick={() => handleNavLinkClick('/UpcomingEventDash')}
              >
                <FontAwesomeIcon icon={faUsers} className="me-2" />
                Upcoming Events
              </NavLink>
            </li>
          </ul>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
