import './PageStyles.css';

import React from 'react';
import ScholarshipsImage from '../Component/photos _41.jpg';

const Scholarships = () => {
  return (
    <div className="page">
      <div className="image_container">
        <img
          src={ScholarshipsImage}
          alt="Scholarships for Higher Education"
          className="img-fluid large_image"
        />
      </div>
      <div className="content">
        <h2>Scholarships for Higher Education</h2>
        <p>
          Education is a powerful tool for breaking the cycle of poverty and
          creating opportunities for a brighter future. At Purvanchal Dastak
          NGO, we are committed to providing scholarships to deserving students
          from underprivileged backgrounds, enabling them to pursue higher
          education and achieve their academic and professional goals.
        </p>
        <h3>Our Mission</h3>
        <p>
          Our mission is to support talented and motivated students who lack the
          financial resources to continue their education. We aim to:
        </p>
        <ul>
          <li>
            Empower students by providing financial assistance for tuition and
            related expenses.
          </li>
          <li>Encourage academic excellence and personal growth.</li>
          <li>
            Ensure that financial constraints do not hinder a student's ability
            to pursue their educational aspirations.
          </li>
        </ul>

        <h3>Scholarship Benefits</h3>
        <h4>Financial Support:</h4>
        <ul>
          <li>
            Coverage of tuition fees for various undergraduate and postgraduate
            programs.
          </li>
          <li>
            Assistance with other educational expenses such as books, supplies,
            and laboratory fees.
          </li>
        </ul>

        <h4>Academic Support:</h4>
        <ul>
          <li>
            Access to mentoring and guidance from experienced professionals and
            educators.
          </li>
          <li>
            Opportunities for internships and career development programs to
            enhance practical skills.
          </li>
        </ul>

        <h4>Personal Development:</h4>
        <ul>
          <li>
            Workshops and seminars on leadership, communication, and
            professional skills.
          </li>
          <li>
            Networking opportunities with peers and industry leaders to foster
            growth and collaboration.
          </li>
        </ul>

        <h3>Application Process</h3>
        <ul>
          <li>
            <strong>Eligibility:</strong> Students from underprivileged
            backgrounds who demonstrate academic excellence and financial need
            are eligible to apply.
          </li>
          <li>
            <strong>Application Form:</strong> Interested students must complete
            an application form, providing details about their academic
            performance, financial situation, and career goals.
          </li>
          <li>
            <strong>Selection Criteria:</strong> Applications are reviewed based
            on academic merit, financial need, and personal statements.
            Shortlisted candidates may be invited for interviews or additional
            evaluations.
          </li>
        </ul>

        <h3>Why Your Support Matters</h3>
        <ul>
          <li>
            <strong>Breaking the Cycle of Poverty:</strong> Scholarships provide
            access to education, which is a key factor in overcoming poverty and
            achieving long-term success.
          </li>
          <li>
            <strong>Fostering Future Leaders:</strong> Supporting education
            helps nurture the next generation of leaders, innovators, and
            professionals.
          </li>
          <li>
            <strong>Creating Equal Opportunities:</strong> Financial aid ensures
            that talented students have equal opportunities to pursue higher
            education, regardless of their financial background.
          </li>
        </ul>

        <h3>How You Can Contribute</h3>
        <ul>
          <li>
            <strong>Donate:</strong> Your financial contributions help fund
            scholarships and support students in need.
          </li>
          <li>
            <strong>Sponsor a Scholarship:</strong> Consider sponsoring a
            specific scholarship to support a student's educational journey.
          </li>
          <li>
            <strong>Spread the Word:</strong> Share our scholarship programs
            with others to raise awareness and encourage more support for
            education.
          </li>
        </ul>

        <h3>Contact Us</h3>
        <p>
          For more information about our scholarship programs or to find out how
          you can get involved, please contact us:
        </p>
        <p>
          Email:{' '}
          <a href="mailto:contact@purvanchaldastak.org">
            Purvanchaldastak1@gmail.com
          </a>
          <br />
          Phone: +91 8318730799, +91 8563058592
        </p>
      </div>
    </div>
  );
};

export default Scholarships;
