import './CareerServices.css';
import BannerImage from '../../Component/Service.jpg';
import React from 'react';
import volunteer1 from '../../Component/photos _48.jpg';
import volunteer2 from '../../Component/photos .jpg';
import volunteer3 from '../../Component/photos _50.jpg';
import volunteer4 from '../../Component/photos _49.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGraduationCap,
  faBook,
  faBriefcase,
  faUserFriends,
  faHandsHelping,
  faLaptopCode,
  faStar,
  faTrophy,
  faMedal,
  faUserGraduate,
  faChalkboardTeacher,
  faAward,
  faHandHoldingHeart,
  faBookReader,
} from '@fortawesome/free-solid-svg-icons';

const keyPersonnel = [
  {
    name: 'Naim Akhtar Siddiqui',
    title: 'Fellow Chartered Accountant',
    description: 'Over 25 years in tax advisory, corporate financing, and investment banking.',
    image: volunteer1,
  },
  {
    name: 'Mahmood Hasan Khan',
    title: 'MBA (Finance)',
    description: '20+ years in corporate finance and fund syndication.',
    image: volunteer2,
  },
  {
    name: 'Mohd. Nisar Ansari',
    title: 'ICAI Member',
    description: 'Expertise in project financing, government audits, forensic audit, and tax consultancy.',
    image: volunteer3,
  },
  {
    name: 'Kalimul Haq',
    title: 'Entrepreneur',
    description: 'Experience in various sectors including hospitality and education.',
    image: volunteer4,
  },
];

const services = [
  { icon: faGraduationCap, text: 'Free coaching for all students in Purvanchal.' },
  { icon: faBook, text: 'Information about government jobs and scholarships.' },
  { icon: faLaptopCode, text: 'Training under skill development programs and placement assistance.' },
  { icon: faBriefcase, text: 'Local job placements assistance in private companies.' },
  { icon: faUserFriends, text: 'Career counseling sessions in schools and colleges.' },
  { icon: faHandsHelping, text: 'Financial support for economically weaker students.' },
];

const achievementDetails = [
  { icon: faStar, text: 'Facilitated preparation for various government and non-government exams.' },
  { icon: faTrophy, text: 'Organized CCC computer courses for 510 students, with 360 passing.' },
  { icon: faMedal, text: 'Provided free computer courses to 425 students with certifications.' },
  { icon: faUserGraduate, text: 'Distributed uniforms to 400 children in three schools.' },
  { icon: faChalkboardTeacher, text: 'Trained 100 girls in sewing and embroidery, with some receiving employment.' },
  { icon: faAward, text: 'Assisted 6000 students with online form filling and other services over 7 years.' },
  { icon: faHandHoldingHeart, text: 'Helped over 435 children with fees and books post-COVID, using Zakat funds.' },
  { icon: faBookReader, text: 'Offered books for competitive exams through a book bank.' },
];

const CareerServices = () => {
  return (
    <div>
      {/* Banner Section */}
      <div
        className="banner-container mt-5"
        style={{
          backgroundImage: `url(${BannerImage})`,
        }}
      >
        <div className="banner-text mt-5">
          <h1 className="banner-heading">Purvanchal Career Support</h1>
          <p className="banner-description">
            Established in Nov. 2014, our Career Guidance Centre offers free coaching, job updates, and support
            services to financially marginalized communities.
          </p>
        </div>
      </div>

      <div className="container mt-5">
        {/* Services Section */}
        <h2 className="section-title">Our Services</h2>
        <div className="row">
          {services.map((service, index) => (
            <div className="col-md-4 mb-4" key={index}>
              <div className="service-card">
                <FontAwesomeIcon icon={service.icon} className="service-icon" />
                <p>{service.text}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Achievements Section */}
        <h2 className="section-title">Our Achievements</h2>
        <div className="row">
          {achievementDetails.map((achievement, index) => (
            <div className="col-md-4 mb-4" key={index}>
              <div className="achievement-card">
                <FontAwesomeIcon icon={achievement.icon} className="achievement-icon" />
                <p>{achievement.text}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Key Personnel Section */}
        <h2 className="section-title">Key Personnel</h2>
        <div className="row">
          {keyPersonnel.map((person, index) => (
            <div className="col-lg-3 col-md-6 mb-4" key={index}>
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img src={person.image} alt={person.name} className="img-fluid rounded-circle mb-3" />
                    <h5>{person.name}</h5>
                    <p className="text-muted">{person.title}</p>
                  </div>
                  <div className="flip-card-back">
                    <p>{person.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CareerServices;
