import './PageStyles.css';

import ComputerEducationImage from '../Component/photos _15.jpg';
import React from 'react';

const ComputerCourses = () => {
  return (
    <div className="page">
      <div className="image_container">
        <img
          src={ComputerEducationImage}
          alt="Computer Courses for Underprivileged Students"
          className="img-fluid large_image"
        />
      </div>
      <div className="content">
        <h2>Computer Courses for Underprivileged Students</h2>
        <p>
          Access to computer education is essential in the digital age. At
          Purvanchal Dastak, we are dedicated to providing computer courses and
          training to underprivileged students. By offering these opportunities,
          we aim to equip young minds with valuable skills that will empower
          them to succeed in a technology-driven world.
        </p>
        <h3>Why Computer Education Matters</h3>
        <p>
          In today's digital era, computer literacy is not just an advantage but
          a necessity. Here’s why providing computer education to
          underprivileged students is crucial:
        </p>
        <ul>
          <li>
            <strong>Bridging the Digital Divide:</strong> Access to technology
            and training helps bridge the gap between those with and without
            resources, ensuring equal opportunities for all students.
          </li>
          <li>
            <strong>Enhancing Future Opportunities:</strong> Skills in computer
            use and programming open doors to various career paths and
            educational opportunities, fostering long-term success.
          </li>
          <li>
            <strong>Promoting Critical Thinking:</strong> Learning computer
            science and digital skills enhances problem-solving abilities and
            critical thinking, essential for personal and professional growth.
          </li>
        </ul>
        <h3>How You Can Support</h3>
        <p>
          Your support can make a significant impact on the lives of
          underprivileged students. Here’s how you can help:
        </p>
        <ul>
          <li>
            <strong>Donate:</strong> Contribute financially to support our
            computer education programs and help us provide necessary resources
            and training.
          </li>
          <li>
            <strong>Volunteer:</strong> Offer your expertise or time to teach
            computer courses or assist with program administration and
            development.
          </li>
          <li>
            <strong>Spread the Word:</strong> Share our mission with others to
            raise awareness and encourage more people to get involved in
            supporting computer education for underprivileged students.
          </li>
        </ul>
        <h3>Get Involved</h3>
        <p>
          To learn more about our computer education initiatives or to find out
          how you can get involved, please contact us:
        </p>
        <p>
          Email:{' '}
          <a href="mailto:contact@purvanchaldastak.org">
            Purvanchaldastak1@gmail.com
          </a>
          <br />
          Phone: +91 8318730799, +91 8563058592
        </p>
      </div>
    </div>
  );
};

export default ComputerCourses;
