import { Navigate } from 'react-router-dom';
import React from 'react';

const ProtectedRoute = ({ children, isLoggedIn, handleShowLogin }) => {
  const loggedIn = isLoggedIn || localStorage.getItem('isLoggedIn') === 'true';

  if (!loggedIn) {
    handleShowLogin();
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute;
