import './Help.css';

import { Link } from 'react-router-dom';
import React from 'react';
import img1 from '../../Component/photos _3.jpg';
import img2 from '../../Component/photos _8.jpg';
import img3 from '../../Component/photos _1.jpg';

const ReasonOfHelping = () => {
  return (
    <div className="reson_area section_padding">
      <div className="background-image" />
      <div className="">
        <div className="row justify-content-center ">
          <div className="col-lg-12">
            <div className="section_title text-center mb-55">
              <h3>{/* <span className="text">Reason of Helping</span> */}</h3>
            </div>
          </div>
        </div>
        <div className="row justify-content-center ">
          <div className="col-lg-4 col-md-12">
            <div className="single_reson">
              <div className="thum">
                <img
                  src={img1}
                  alt="Collecting Fund"
                  className="reson_image img-fluid"
                />
              </div>
              <div className="help_content">
                <h4>Concept of Computer Course (CCC)</h4>
                <p>
                  Empower yourself with the CCC (Course on Computer Concepts)
                  provided by our Purvanchal Dastak.
                </p>
                <Link to="/stitching-women" className="read_more">
                  Read More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="single_reson">
              <div className="thum">
                <img
                  src={img2}
                  alt="Blood Camp"
                  className="reson_image img-fluid"
                />
              </div>
              <div className="help_content">
                <h4>Coaching For Tally Course</h4>
                <p>
                  At Purvanchal Dastak NGO, we believe that quality education is
                  a powerful tool for social change...
                </p>
                <Link to="/coaching-government-job" className="read_more">
                  Read More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="single_reson">
              <div className="thum">
                <img
                  src={img3}
                  alt="Friendly Volunteer"
                  className="reson_image img-fluid"
                />
              </div>
              <div className="help_content">
                <h4>Computer Training to Women</h4>
                <p>
                  In today’s digital age, computer literacy is essential for
                  personal and professional growth...
                </p>
                <Link to="/computer-training" className="read_more">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReasonOfHelping;
