import { Button, Form, Input, Modal, Select, message } from 'antd';
import React, { useState } from 'react';

import axios from 'axios'; // Import axios for making API requests

const { Option } = Select;

const SignupModal = () => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm(); // Use form instance for handling form data

  const handleSignupOk = async () => {
    try {
      const values = await form.validateFields(); // Validate form fields
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/signup`, // Use .env API base URL
        values
      ); 
  
      message.success(response.data.message);
      form.resetFields(); // Reset form fields
      setVisible(false); // Close modal
    } catch (error) {
      console.error('Signup failed:', error);
      message.error(error.response?.data?.message || 'Signup failed. Please try again.');
    }
  };
  

  const handleSignupCancel = () => {
    setVisible(false);
  };

  return (
    <>
      {/* Signup Button */}
      <Button
        type="primary"
        onClick={() => setVisible(true)}
        style={{
          marginBottom: 16,
          backgroundColor: 'green',
          borderColor: 'green',
        }}
      >
        Signup
      </Button>

      {/* Signup Modal */}
      <Modal
        title="Signup"
        visible={visible}
        onOk={handleSignupOk}
        onCancel={handleSignupCancel}
        okButtonProps={{
          style: {
            backgroundColor: 'green',
            borderColor: 'green',
            color: 'white',
          },
        }}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please input a valid email!',
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item label="Role" name="role" initialValue="admin">
            <Select>
              <Option value="admin">Admin</Option>
              {/* Add more roles if needed */}
              {/* <Option value="user">User</Option> */}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SignupModal;
