import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import VolunteerSection from './../Volunteer/Volunteer';
import VolunteerSectionimg from '../../Component/Volunteer.jpg';

const VolunteerSections = () => {
  return (
    <div>
      <div className="w-100 position-relative" style={{ height: '70vh' }}>
        <img
          src={VolunteerSectionimg}
          alt="VolunteerSections"
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
        <div className="position-absolute top-50 start-50 translate-middle text-center text-white">
          {/* <h1 className="fs-1 fw-bold ">Latest Cause</h1> */}
        </div>
      </div>
      <div style={{ marginTop: '-30px' }}>
        {' '}
        {/* Adjust this value as needed */}
        <VolunteerSection />
      </div>
    </div>
  );
};

export default VolunteerSections;
