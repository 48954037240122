import './PageStyles.css';

import React from 'react';
import largeImage from '../Component/photos _1.jpg';

const ComputerTraining = () => {
  return (
    <div className="page">
      <div className="image_container">
        <img
          src={largeImage}
          alt="Computer Training to Women"
          className="img-fluid large_image"
        />
      </div>
      <div className="content">
        <h2>Computer Training to Women</h2>
        <p>
          In today’s digital age, computer literacy is essential for personal
          and professional growth. Access to technology and digital skills can
          significantly improve employment opportunities and empower women in
          various aspects of their lives. Our Free Computer Training Program
          aims to provide women with the skills and confidence needed to excel
          in a technology-driven world.
        </p>
        <p>
          Participants learn to use various software, navigate the internet, and
          understand basic computer functions. This knowledge opens up new
          avenues for employment, education, and personal development. By
          bridging the digital divide, we help women gain the tools they need to
          succeed in modern society.
        </p>
        <h3>Objective:</h3>
        <p>
          To offer free, accessible, and comprehensive computer training to
          women, enhancing their digital skills and opening doors to educational
          and employment opportunities.
        </p>
        <h3>Training Program Details:</h3>
        <h4>Basic Computer Skills:</h4>
        <ul>
          <li>
            Introduction to Computers: Understanding the components, operating
            systems, and basic functions of a computer.
          </li>
          <li>
            Keyboard and Mouse Skills: Training on typing, using the mouse, and
            basic navigation techniques.
          </li>
        </ul>
        <h4>Office Software Training:</h4>
        <ul>
          <li>
            Microsoft Office Suite: Hands-on training in Word, Excel, and
            PowerPoint, including document creation, data management, and
            presentation skills.
          </li>
          <li>
            Google Workspace: Training in Google Docs, Sheets, and Slides for
            cloud-based document creation and collaboration.
          </li>
        </ul>
        <h4>Internet and Email:</h4>
        <ul>
          <li>
            Internet Navigation: Skills to browse the web safely, conduct
            research, and use online resources effectively.
          </li>
          <li>
            Email Management: Setting up and managing email accounts, composing
            and sending emails, and using email tools efficiently.
          </li>
        </ul>
        <h4>Digital Literacy:</h4>
        <ul>
          <li>
            Online Safety and Security: Understanding privacy settings,
            protecting personal information, and recognizing online scams.
          </li>
          <li>
            Social Media: Basics of using social media platforms like Facebook,
            Twitter, and LinkedIn for personal and professional networking.
          </li>
        </ul>
        <h4>Advanced Skills (Optional):</h4>
        <ul>
          <li>
            Introduction to Programming: Basic programming concepts and
            languages such as Python or HTML/CSS for those interested in coding.
          </li>
          <li>
            Graphic Design and Multimedia: Basics of graphic design using tools
            like Canva or Adobe Photoshop, and multimedia editing skills.
          </li>
        </ul>
        <h4>Career and Job Readiness:</h4>
        <ul>
          <li>
            Resume Building: Creating and formatting resumes and cover letters.
          </li>
          <li>
            Job Search Skills: Using online job portals, applying for jobs, and
            preparing for interviews.
          </li>
          <li>
            Freelancing and Online Work: Introduction to freelance work and
            opportunities for online earning.
          </li>
        </ul>
        <h3>Program Features:</h3>
        <ul>
          <li>
            <strong>Free Access:</strong> All training materials, sessions, and
            resources are provided at no cost to participants. No prior computer
            knowledge is required to join the program.
          </li>
          <li>
            <strong>Flexible Scheduling:</strong> Classes are offered at various
            times to accommodate different schedules, including evening and
            weekend sessions. Online and in-person options are available to
            cater to diverse needs.
          </li>
          <li>
            <strong>Support and Mentorship:</strong> Access to experienced
            trainers and mentors who provide personalized support and answer
            questions. Ongoing support through forums or chat groups where
            participants can connect and seek help.
          </li>
          <li>
            <strong>Certification:</strong> Participants receive certificates
            upon completing the training program, which can be added to resumes
            and used to demonstrate newly acquired skills.
          </li>
          <li>
            <strong>Community Building:</strong> Opportunities to connect with
            other women in the program, share experiences, and build a
            supportive network.
          </li>
        </ul>
        <h3>How to Enroll:</h3>
        <h4>Application Process:</h4>
        <ul>
          <li>
            Interested women can apply through our website or local community
            centers. Application forms are simple and require basic information
            about the applicant’s background and goals.
          </li>
          <li>
            Selection Criteria: Open to all women, with a focus on those who are
            economically disadvantaged or have limited access to technology.
            Priority may be given to individuals from marginalized communities.
          </li>
        </ul>
        <h4>Orientation:</h4>
        <p>
          An orientation session will be held to introduce participants to the
          program, outline expectations, and provide an overview of the training
          schedule.
        </p>
        <h3>Impact of the Program:</h3>
        <ul>
          <li>
            <strong>Empowerment:</strong> Equipping women with computer skills
            enhances their confidence and independence in both personal and
            professional settings.
          </li>
          <li>
            <strong>Employment Opportunities:</strong> Improved computer skills
            increase employability and open doors to various career paths.
          </li>
          <li>
            <strong>Community Development:</strong> Skilled women contribute
            positively to their communities by fostering technology literacy and
            sharing knowledge.
          </li>
        </ul>
        <h3>How You Can Support:</h3>
        <ul>
          <li>
            <strong>Volunteer:</strong> Share your expertise by volunteering as
            a trainer or mentor in the program.
          </li>
          <li>
            <strong>Donate:</strong> Contribute funds or resources to support
            the training sessions, including computers and software.
          </li>
          <li>
            <strong>Partner with Us:</strong> Collaborate with organizations or
            businesses to provide additional resources, internships, or job
            placements for participants.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ComputerTraining;
