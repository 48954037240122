import "bootstrap/dist/css/bootstrap.min.css";
import "./About.css";
import Aboutimg from "../../Component/Aboutus.jpg";
import Aboutimg1 from "../../Component/photos _16.jpg";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import DonationModal from "../../DonationModal";

const About = () => {
  const [showDonationModal, setShowDonationModal] = useState(false); // State to control the donation modal

  return (
    <div>
      <div className="w-100" style={{ height: "70vh", overflow: "hidden" }}>
        <img
          src={Aboutimg}
          alt="About Us"
          style={{
            width: "100%",
            height: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
      </div>

      <div className="container-fluid">
        <div className="row no-gutters">
          <div className="col-md-6 mt-4">
            <div className="container img-fluid">
              <img src={Aboutimg1} alt="Water" className="img-fluid" />
            </div>
          </div>

          <div className="col-md-6 d-flex align-items-center p-5">
            <div>
              <h2 className="mb-4">About Us</h2>
              <p>
                Purvanchal Dastak is an NGO based in Gorakhpur, Uttar Pradesh,
                and aims to provide guidance and counselling to students. For
                years, it was perceived that students passing out from schools,
                colleges, and universities often lacked the extra ‘edge’ to
                succeed in competitive exams and secure rewarding jobs.
              </p>
              <p>
                For a long time, the need was felt to have a service that would
                enrich students with information, provide proper guidance, and
                facilitate their success in exams. We are a group of
                professionals who have come together to provide this service
                absolutely free of cost.
              </p>
              <p>
                Purvanchal Dastak has established a Facilitation Centre in
                Qazipur Khurd, Gorakhpur, where students and guardians alike can
                visit and get the latest information and guidance on higher
                education, career counselling, opportunities in various sectors,
                and competitive exams.
              </p>

              <h2>The Vision</h2>
              <p>
                We aim to contribute to building an enlightened and caring
                nation and society where the poor and underprivileged,
                irrespective of caste, creed, or religion, can live a life of
                dignity and self-sufficiency, which are their rightful
                entitlements.
              </p>

              <h2>The Mission</h2>
              <p>
                We strive to improve the society we live and work in by creating
                an environment where people have access to opportunities for
                education and employment. We also aim to improve opportunities
                for the underprivileged, help the youth and students lead better
                lives, and bring about social change for greater opportunities,
                while promoting integrated development for all.
              </p>
              <p>Our specific mission includes:</p>
              <ul>
                <li>
                  Informing and educating students about career and educational
                  opportunities,
                </li>
                <li>Facilitating students' access to these opportunities,</li>
                <li>Providing guidance and counselling for students,</li>
                <li>
                  Wherever possible, arranging monetary help and support for
                  students to make these opportunities accessible,
                </li>
                <li>
                  Offering general services in education, employment, and
                  integrated community development through welfare, monetary
                  assistance, and more,
                </li>
                <li>
                  Assisting the Government of India and other NGOs in promoting
                  schemes and programs for societal upliftment.
                </li>
              </ul>

              <h2>Our Values</h2>
              <p>
                We uphold honesty in all aspects of our work, compassion and
                empathy for everyone, transparency in our operations and
                accounting, and a passion for serving those in need.
              </p>
              <p>
                <b>“A mission to achieve the best for those we serve.”</b>
              </p>
            </div>
          </div>
        </div>
      </div>

      <section
        className="ftco-counter ftco-intro ftco-intro-2"
        id="section-counter"
      >
        <div className="container">
          <div className="row no-gutters">
            <div className="col-md d-flex justify-content-center counter-wrap ftco-animate fadeInUp ftco-animated">
              <div className="block-18 color-3 align-items-stretch">
                <div className="text">
                  <h3 className="mb-4">Donate Money</h3>
                  <p>
                    Your financial contributions help us expand our programs and
                    reach more individuals in need.
                  </p>
                  <p>
                    <button
                      className="btn btn-white px-3 py-2 mt-2"
                      onClick={() => setShowDonationModal(true)} // Open modal on click
                    >
                      Donate Now
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md d-flex justify-content-center counter-wrap ftco-animate fadeInUp ftco-animated">
              <div className="block-18 color-3 align-items-stretch">
                <div className="text">
                  <h3 className="mb-4">Our Founder Member</h3>
                  <p>
                    Contribute your time and skills to support our various
                    programs and make a direct impact in the community.
                  </p>
                  <p>
                    <Link
                      to="/Volunteers"
                      className="btn btn-white px-3 py-2 mt-2"
                    >
                      Our Founder Member
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Donation Modal */}
      <DonationModal
        show={showDonationModal}
        onHide={() => setShowDonationModal(false)} // Close modal
        formType="donation" // Pass form type
      />
    </div>
  );
};

export default About;
