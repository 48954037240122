import Donate from "../../Pages/Donation/Donation";
// import Event from '../../Pages/Events/Event';
import Help from "../../Pages/Help/Help";
import LatestActivities from "../Activity/Activity";
import Popular from "./../Popular/Popular";
// import PopularCauses from '../../Pages/Popular/Popular';
import Profile from "../../Pages/Profile/Profile";
import React from "react";
import Socialimage from "./../SocialEvent/Socialimage";
import Volunteer from "../../Pages/Volunteer/Volunteer";
import OurTeam from "../Team-Member/Teammember";
const Home = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <Profile />

        <Socialimage />

        <Help />

        <LatestActivities />

        <Popular />
        {/* 
        <Event /> */}

        <Volunteer />
        {/* < OurTeam /> */}
        <Donate />
      </div>
    </div>
  );
};

export default Home;
