import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Form, Input, Modal, Table, Upload, message } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import SignupModal from "../../Pages/SignupModal/SignupModal";
import axios from "axios";

const SocialEventDash = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [signupVisible, setSignupVisible] = useState(true);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Define the base URL

  // Function to fetch events from the server
  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/events`);
      setData(response.data);
      localStorage.setItem("events", JSON.stringify(response.data));
      console.log("Fetched events:", response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  // Fetch events on component mount
  useEffect(() => {
    const storedEvents = localStorage.getItem("events");
    if (storedEvents) {
      setData(JSON.parse(storedEvents));
    }
    fetchEvents();
  }, []);

  const columns = [
    { title: "Title", dataIndex: "title", key: "title" },
    { title: "Description", dataIndex: "description", key: "description" },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (image) => (
        <img
          src={image ? `${API_BASE_URL}${image}` : ""}
          alt="Event"
          style={{ width: 50, height: 50, objectFit: "contain" }}
        />
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <span>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleSoftDelete(record.id)}
          >
            Delete
          </Button>
        </span>
      ),
    },
  ];

  const addOrEditEvent = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    if (fileList.length > 0) {
      formDataToSend.append("image", fileList[0].originFileObj);
    }

    try {
      if (formData.id) {
        await axios.put(
          `${API_BASE_URL}/api/events/edit/${formData.id}`,
          formDataToSend,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        message.success("Event updated successfully");
      } else {
        await axios.post(`${API_BASE_URL}/api/events/add`, formDataToSend, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        message.success("Event added successfully");
      }

      // Fetch the latest data after adding/updating
      await fetchEvents();

      setOpen(false);
      setFormData({});
      setFileList([]);
    } catch (error) {
      message.error("Error processing the event");
      console.error("Error:", error);
    }
  };

  const handleSoftDelete = async (id) => {
    try {
      await axios.patch(`${API_BASE_URL}/api/events/soft-delete/${id}`);
      await fetchEvents(); // Fetch updated data after deletion
      message.success("Event soft-deleted successfully");
    } catch (error) {
      message.error("Error deleting the event");
      console.error("Error:", error);
    }
  };

  const handleEdit = (record) => {
    setOpen(true);
    setFormData(record);
    setFileList([]);
  };

  const handleAdd = () => {
    setOpen(true);
    setFormData({});
    setFileList([]);
  };

  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList);
  };

  return (
    <div className="table-container" style={{ marginTop: "70px" }}>
      <div className="d-flex" style={{ height: "100%" }}>
        <div className="sideBardWidth">
          <Sidebar />
        </div>
        <div style={{ flex: 1, overflowY: "auto", padding: "20px" }}>
          <h3 className="text-center mt-4">Social Events</h3>
          <div className="d-flex justify-content-end mb-3">
            <Button
              type="primary"
              onClick={handleAdd}
              style={{
                marginRight: 8,
                backgroundColor: "green",
                borderColor: "green",
              }}
            >
              Add New Event
            </Button>
            <SignupModal
              visible={signupVisible}
              onClose={() => setSignupVisible(false)}
              style={{ marginLeft: "8px" }}
            />
          </div>
          <div
            style={{ overflowY: "auto", maxHeight: "400px", marginTop: "20px" }}
          >
            <Table
              columns={columns}
              dataSource={data.filter((item) => !item.deleted)}
              rowKey="id"
              rowClassName={() => "hoverable-row"}
            />
          </div>
          <Modal
            title={formData.id ? "Edit Event" : "Add New Event"}
            open={open}
            onOk={addOrEditEvent}
            onCancel={() => setOpen(false)}
            bodyStyle={{ overflowY: "auto", maxHeight: "70vh" }}
          >
            <Form layout="vertical">
              <Form.Item label="Title">
                <Input
                  name="title"
                  value={formData.title || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item label="Description">
                <Input.TextArea
                  name="description"
                  value={formData.description || ""}
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>
              <Form.Item label="Image">
                <Upload
                  listType="picture"
                  fileList={fileList}
                  onChange={handleUploadChange}
                  beforeUpload={() => false}
                >
                  <Button icon={<UploadOutlined />}>Upload Image</Button>
                </Upload>
                {formData.image && (
                  <img
                    src={`${API_BASE_URL}${formData.image}`}
                    alt="Event"
                    style={{ width: 50, height: 50, marginTop: "10px" }}
                  />
                )}
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default SocialEventDash;
