import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import SocialImage from "../../Component/SocialEvent1.jpg";
import axios from "axios";

const UpcomingEvents = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  // Fetch upcoming events from the backend API
  useEffect(() => {
    const fetchUpcomingEvents = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/Upcoming` // Use .env API base URL
        );
        setUpcomingEvents(response.data);
      } catch (error) {
        console.error("Error fetching upcoming events:", error);
      }
    };
    fetchUpcomingEvents();
  }, []);

  return (
    <div>
      <div className="w-100 position-relative" style={{ height: "70vh" }}>
        <img
          src={SocialImage}
          alt="Upcoming Events"
          style={{
            width: "100%",
            height: "100%",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
      </div>

      {/* Upcoming Events Section */}
      <div className="container my-5">
        {upcomingEvents.length === 0 ? (
          <p>No upcoming events found</p>
        ) : (
          upcomingEvents.map((event) => (
            <div key={event.id} className="row mb-5 align-items-center">
              <div className="col-md-6">
                <img
                  src={`${API_BASE_URL}${event.image}`}
                  alt={event.title}
                  className="img-fluid rounded"
                  style={{ objectFit: "cover", height: "100%" }}
                />
              </div>
              <div className="col-md-6">
                <h2>{event.title}</h2>
                <p>{event.description}</p>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default UpcomingEvents;
