import "./Contactus.css";

import React, { useState } from "react";

import Whatsapp from "../../Component/whatsapp.png";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    Gmail: "",
    message: "",
  });
  const [formStatus, setFormStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://formsubmit.co/arifalikhan01986@gmail.com",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setFormStatus("Message sent successfully!");
      } else {
        setFormStatus("Failed to send message. Please try again later.");
      }
    } catch (error) {
      setFormStatus("Failed to send message. Please try again later.");
    }
  };

  return (
    <div className="contact-page">
      <div className="background-container"></div>
      <div className="content-container">
        <div className="container">
          <div className="contact-content">
            <div className="row">
              <div className="col-md-6">
                <h4 className="Sizing">Contact</h4>
                <h1 className="Font">Get In Touch</h1>
                <p>
                  For any inquiries, partnerships, or to learn more about how
                  you can support our cause, please contact us at
                  <a href="mailto:purvanchaldastak1@gmail.com">
                    {" "}
                    purvanchaldastak1@gmail.com
                  </a>{" "}
                  or call us at
                  <a href="tel:+918318730799"> +91 8318730799</a>.
                  <br />
                  You can also visit our office at{" "}
                  <a
                    href="https://www.google.com/maps?q=Mohallah+Chhote+Qazipur,+Near+Old+LIU+Office,+Gorakhpur+U.P.+(India)+273001"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Mohallah Chhote Qazipur, Near Old LIU Office, Gorakhpur U.P.
                    (India) 273001
                  </a>
                  .
                  <br />
                  Office Timing: 9:00 a.m. to 5:00 p.m. (Sunday Open)
                </p>
              </div>
              <div className="col-md-6">
                <form className="contact-form" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="form-control"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="Gmail">Email Address</label>
                    <input
                      type="email"
                      id="Gmail"
                      name="Gmail"
                      className="form-control"
                      value={formData.Gmail}
                      onChange={handleChange}
                      placeholder="Email Address"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                      id="message"
                      name="message"
                      className="form-control"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </form>
                {formStatus && <p className="form-status">{formStatus}</p>}
              </div>
            </div>
            <div className="contact-row row mt-5">
              <div className="col-md-3">
                <div className="blurb">
                  <a
                    href="mailto:purvanchaldastak1@gmail.com"
                    className="blurb-link"
                  >
                    <img
                      src="http://zaib.sandbox.etdevs.com/ngo/wp-content/uploads/sites/22/2021/09/ngo-icon-16.png"
                      alt="Gmail Icon"
                      className="blurb-icon"
                    />
                    <h4>Gmail</h4>
                  </a>
                  <p>
                    <a href="mailto:purvanchaldastak1@gmail.com">
                      purvanchaldastak1@gmail.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="blurb">
                  <a href="tel:+918318730799" className="blurb-link">
                    <img
                      src="http://zaib.sandbox.etdevs.com/ngo/wp-content/uploads/sites/22/2021/09/ngo-icon-18.png"
                      alt="Phone Icon"
                      className="blurb-icon"
                    />
                    <h4>Phone</h4>
                  </a>
                  <p>
                    <a href="tel:+918318730799">+91 8318730799</a>
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="blurb">
                  <a
                    href="https://www.google.com/maps?q=Mohallah+Chhote+Qazipur,+Near+Old+LIU+Office,+Gorakhpur+U.P.+(India)+273001"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="blurb-link"
                  >
                    <img
                      src="http://zaib.sandbox.etdevs.com/ngo/wp-content/uploads/sites/22/2021/09/ngo-icon-17.png"
                      alt="Address Icon"
                      className="blurb-icon"
                    />
                    <h4>Address</h4>
                  </a>
                  <p>
                    <a
                      href="https://www.google.com/maps?q=Mohallah+Chhote+Qazipur,+Near+Old+LIU+Office,+Gorakhpur+U.P.+(India)+273001"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Mohallah Chhote Qazipur,
                      <br />
                      Near Old LIU Office,
                      <br />
                      Gorakhpur U.P. (India) 273001
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-md-3">
                <div className="blurb">
                  <a
                    href="https://wa.me/918318730799"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="blurb-link"
                  >
                    <img
                      src={Whatsapp}
                      alt="WhatsApp Icon"
                      className="blurb-icon"
                    />
                    <h4>WhatsApp</h4>
                  </a>
                  <p>
                    <a
                      href="https://wa.me/918318730799"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Chat with us on WhatsApp
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
