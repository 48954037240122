import './PageStyles.css';

import Activity from '../Component/photos _13.jpg';
import React from 'react';

const Zakat = () => {
  return (
    <div className="page mt-4">
      <div className="image_container mb-4">
        <img
          src={Activity}
          alt="Zakat Initiative"
          className="img-fluid rounded"
        />
      </div>
      <div className="content">
        <h3 className="mt-4">About Purvanchal Dastak</h3>
        <p>
          Purvanchal Dastak is constantly working for the welfare of
          economically weaker Muslim/minority students (both girls and boys). It
          was started in November 2014 by a group of professionals and
          academicians. We appeal to all of you to donate some part of your
          Zakat amount, either in cash, through cheque, or directly into the
          exclusive account of Zakat only.
        </p>
        <h2 className="mb-4">
          Provide Uniform and Stationery to Underprivileged Children Through
          Zakat
        </h2>
        <p>
          Education is a fundamental right, but many underprivileged children
          are deprived of it due to financial constraints. Through Zakat, a
          powerful tool in Islam for social welfare, we can bridge this gap by
          providing essential resources such as uniforms, stationery, and
          directly paying school fees. This initiative not only supports their
          education but also empowers them to build a better future.
        </p>

        <h3 className="mt-4">Our Mission</h3>
        <ul>
          <li>
            <strong>Provide School Uniforms:</strong> Ensuring that every child
            has the dignity and confidence to attend school.
          </li>
          <li>
            <strong>Supply Stationery:</strong> From notebooks and pens to
            school bags, we ensure that children have the tools they need to
            succeed academically.
          </li>
          <li>
            <strong>Submit Fees Directly to Schools:</strong> We work with
            schools to directly pay fees, ensuring transparency and preventing
            misuse of funds.
          </li>
        </ul>
        <h3 className="mt-4">How Zakat Helps</h3>
        <ul>
          <li>
            <strong>Supporting Education:</strong> Your Zakat will directly
            contribute to covering the cost of school fees, uniforms, and
            supplies.
          </li>
          <li>
            <strong>Breaking the Cycle of Poverty:</strong> Education is the
            most effective tool to break the cycle of poverty and create
            opportunities for a brighter future.
          </li>
          <li>
            <strong>Empowering Communities:</strong> By educating children, we
            empower not only individuals but entire communities, paving the way
            for a more educated and self-sufficient society.
          </li>
        </ul>
        <h3 className="mt-4">What We Provide:</h3>
        <ul>
          <li>
            <strong>Uniforms:</strong> Every child receives a complete school
            uniform, which helps them attend school with pride and dignity.
          </li>
          <li>
            <strong>Stationery Kits:</strong> Notebooks, pencils, pens, erasers,
            rulers, and school bags are distributed to ensure children have
            everything they need for their studies.
          </li>
          <li>
            <strong>Direct Fee Payment:</strong> We collaborate with schools to
            directly pay the tuition fees of underprivileged children, ensuring
            that the funds are used as intended.
          </li>
        </ul>
        <h3 className="mt-4">How You Can Help</h3>
        <ul>
          <li>
            <strong>Donate Your Zakat:</strong> You can donate your Zakat to
            this cause, knowing that it will directly impact the education of a
            child in need.
          </li>
          <li>
            <strong>Sponsor a Child:</strong> By sponsoring a child's education,
            you can provide them with uniforms, stationery, and full tuition
            coverage for the entire academic year.
          </li>
          <li>
            <strong>Spread the Word:</strong> Share this cause with friends and
            family, and encourage them to contribute to a meaningful initiative
            that changes lives.
          </li>
        </ul>
        <h3 className="mt-4">Transparency and Accountability</h3>
        <ul>
          <li>
            <strong>Direct Fee Payment:</strong> All fees are paid directly to
            the schools, ensuring no intermediaries or mismanagement.
          </li>
          <li>
            <strong>Regular Updates:</strong> Donors receive updates on the
            progress of the children they are supporting, including school
            performance and achievements.
          </li>
          <li>
            <strong>Financial Reports:</strong> Detailed financial reports are
            provided to donors, ensuring full accountability for every
            contribution made.
          </li>
        </ul>

        <h4 className="mt-4">Account Details</h4>
        <p>
          <strong>Account Holder Name:</strong> PURVANCHAL DUSTAK
          <br />
          <strong>Account No:</strong> 03841131003761
          <br />
          <strong>Bank:</strong> PUNJAB NATIONAL BANK
          <br />
          <strong>Branch:</strong> Bank Road, Gorakhpur, UP
          <br />
          <strong>RTGS/NEFT IFS CODE:</strong> PUNB0038410
        </p>
        <h4 className="mt-4">Contact Details</h4>
        <p>
          You can also deposit your cheque or cash at the Purvanchal Dastak
          office at:
          <br />
          <strong>Chotey Qazipur, Near Old LIU Office, Gorakhpur, UP</strong>
          <br />
          For further details, you can call us at:
          <br />
          <strong>8318730799, 8563058592</strong>
          <br />
          Or email us at:
          <br />
          <strong>purvanchaldastak1@gmail.com</strong>
        </p>
      </div>
    </div>
  );
};

export default Zakat;
