import './PageStyles.css';

import React from 'react';
import largeImage from '../Component/photos _8.jpg';

const CoachingForTallyCourse = () => {
  return (
    <div className="page">
      <div className="image_container">
        <img
          src={largeImage}
          alt="Coaching for Tally Course"
          className="img-fluid large_image"
        />
      </div>
      <div className="content">
        <h2>Coaching For Tally Course</h2>
        <p>
          At Purvanchal Dastak , we understand the importance of practical
          skills in today’s job market. Our Free Coaching for Tally Course is
          designed to provide underprivileged and economically disadvantaged
          individuals with essential skills in accounting and financial
          management using Tally software. With expert guidance and hands-on
          training, we aim to equip candidates with the knowledge needed to
          excel in the field of accounting and enhance their employability.
        </p>
        <p>
          The program includes comprehensive training on Tally software,
          real-world accounting practices, and personalized support to ensure
          that each participant gains the necessary skills to succeed in their
          career. Join us to take advantage of this opportunity to build a
          strong foundation in accounting and secure a better future.
        </p>

        <section className="mission_section">
          <h3>Our Mission</h3>
          <p>
            To empower individuals with practical accounting skills and enhance
            their job prospects through free, high-quality Tally software
            training.
          </p>
        </section>

        <section className="offer_section">
          <h3>What We Offer</h3>
          <ul>
            <li>
              <strong>Expert-Led Training:</strong> Learn from experienced
              instructors with extensive knowledge in Tally and accounting
              practices.
            </li>
            <li>
              <strong>Comprehensive Curriculum:</strong> Our curriculum covers
              all essential aspects of Tally, including invoice management,
              inventory control, payroll processing, and financial reporting.
            </li>
            <li>
              <strong>Hands-On Practice:</strong> Gain practical experience
              through real-world scenarios and case studies.
            </li>
            <li>
              <strong>Personalized Support:</strong> Receive one-on-one guidance
              and support to address your individual learning needs.
            </li>
          </ul>
        </section>

        <section className="why_choose_us">
          <h3>Why Choose Us?</h3>
          <ul>
            <li>
              <strong>Completely Free:</strong> Our Tally course is offered at
              no cost to ensure that financial barriers do not prevent anyone
              from gaining valuable accounting skills.
            </li>
            <li>
              <strong>Focused Training:</strong> We specialize in providing
              practical training for Tally software, a critical tool in the
              accounting profession.
            </li>
            <li>
              <strong>Small Class Sizes:</strong> Personalized attention in
              small class settings ensures effective learning and support.
            </li>
            <li>
              <strong>Career Advancement:</strong> Equip yourself with skills
              that can enhance your career prospects in the accounting and
              finance sectors.
            </li>
          </ul>
        </section>

        <section className="get_involved">
          <h3>Get Involved</h3>
          <ul>
            <li>
              <strong>Become a Volunteer:</strong> If you have expertise in
              accounting or Tally software, join us as a volunteer instructor or
              mentor.
            </li>
            <li>
              <strong>Donate:</strong> Your contributions help us provide free
              training and resources to those who need them most.
            </li>
          </ul>
        </section>

        <section className="early_goal_settings">
          <h3>
            Career Planning for Aspiring Accountants: From High School to
            Graduation
          </h3>
          <p>
            Early career planning is crucial for aspiring accountants. This
            section provides guidance on setting goals and preparing for a
            successful career in accounting, including the use of Tally
            software.
          </p>

          <h4>High School: Building a Foundation</h4>
          <ul>
            <li>
              <strong>Understanding Accounting Basics:</strong> Introduction to
              basic accounting principles and software tools like Tally.
            </li>
            <li>
              <strong>Exploring Career Paths:</strong> Overview of various
              accounting roles and career opportunities.
            </li>
            <li>
              <strong>Setting Academic Goals:</strong> Guidance on choosing
              relevant subjects and extracurricular activities.
            </li>
            <li>
              <strong>Preparing for Further Education:</strong> Advice on
              pursuing relevant college degrees or certifications.
            </li>
          </ul>

          <h4>Undergraduate Studies: Specializing in Accounting</h4>
          <ul>
            <li>
              <strong>Choosing a Degree:</strong> Selecting a degree in
              accounting or finance that aligns with career goals.
            </li>
            <li>
              <strong>Advanced Tally Training:</strong> In-depth training and
              certification in Tally and other accounting software.
            </li>
            <li>
              <strong>Internships and Work Experience:</strong> Gaining
              practical experience through internships or part-time work.
            </li>
            <li>
              <strong>Job Search and Career Planning:</strong> Preparing for job
              placement and long-term career development.
            </li>
          </ul>

          <h4>Post-Graduation: Advancing Your Career</h4>
          <ul>
            <li>
              <strong>Specialization:</strong> Pursuing specialized
              certifications or advanced degrees in accounting.
            </li>
            <li>
              <strong>Professional Development:</strong> Continuous learning and
              skill enhancement through workshops and courses.
            </li>
            <li>
              <strong>Career Opportunities:</strong> Exploring advanced career
              paths and job roles in accounting and finance.
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default CoachingForTallyCourse;
