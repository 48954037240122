import './Navbar.css';
import { Button, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import LoginModal from '../Pages/LoginModal/LoginModal';
import DonationModal from '../DonationModal.js';
import logo from '../Component/Purvanchal Dastak Logo.jpg';

const Header = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showDonation, setShowDonation] = useState(false);
  const [formType, setFormType] = useState(''); // 'donation' or 'zakat'
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false); // Track dropdown open state

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedInStatus);

    if (!loggedInStatus && window.location.pathname === '/dashboard') {
      navigate('/');
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.setItem('isLoggedIn', 'false');
    setIsLoggedIn(false);
    navigate('/');
  };

  const handleLoginSuccess = () => {
    localStorage.setItem('isLoggedIn', 'true');
    setIsLoggedIn(true);
    setShowLogin(false);
    navigate('/dashboard');
  };

  const handleOpenDonationModal = (type) => {
    setFormType(type);
    setShowDonation(true);
  };

  return (
    <>
      <Navbar expand="lg" className="header-bottom bg-white navbar-light" fixed="top">
        <Container fluid>
          <Navbar.Brand as={Link} to="/" className="navbar-brand">
            <img src={logo} alt="Charity Logo" className="navbar-logo" style={{ width: '80px' }} />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="navbar-nav" id="navigation">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/About">About</Nav.Link>
              <Nav.Link as={Link} to="/program">Latest Causes</Nav.Link>

              {/* Dropdown for Social Events with hover functionality */}
              <NavDropdown 
                title="Social Events" 
                id="social-events-dropdown"
                show={dropdownOpen}  // Control visibility using state
                onMouseEnter={() => setDropdownOpen(true)}  // Show dropdown on hover
                onMouseLeave={() => setDropdownOpen(false)}  // Hide dropdown when mouse leaves
              >
                <NavDropdown.Item as={Link} to="/UpcomingEvents">Our Upcoming Events</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/events">Our Latest Events</NavDropdown.Item>
              </NavDropdown>

              <Nav.Link as={Link} to="/CareerServices">Services</Nav.Link>
              <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
              <Button onClick={() => handleOpenDonationModal('donation')} className="header-btn">Donation</Button>
              <Button onClick={() => handleOpenDonationModal('zakat')} className="header-btn">Zakat</Button>

              {isLoggedIn ? (
                <Button onClick={handleLogout} className="header-btn">Logout</Button>
              ) : (
                <Button onClick={() => setShowLogin(true)} className="header-btn">Login</Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Modals */}
      <LoginModal showLogin={showLogin} handleCloseLogin={() => setShowLogin(false)} setIsLoggedIn={handleLoginSuccess} />
      <DonationModal show={showDonation} onHide={() => setShowDonation(false)} formType={formType} />
    </>
  );
};

export default Header;
