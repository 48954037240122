import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import Sidebar from '../Sidebar/Sidebar';

const Dashboard = () => {
  return (
    <div className="d-flex vh-100">
      {/* Sidebar with Bootstrap col and flex */}
      <Sidebar isCollapsed={false} />

      {/* Main Content Area */}
      <div
        className="flex-grow-1 d-flex flex-column justify-content-center align-items-center text-center p-3"
        style={{ width: '100%', paddingLeft: '20px' }}
      >
        {/* Dashboard Heading */}
        <h1 className="display-1 mb-3">Dashboard</h1>

        {/* Dashboard Subtext */}
        <p className="lead" style={{ fontSize: '3.2rem' }}>
          Welcome to the Purvanchal Dashboard!
        </p>
      </div>
    </div>
  );
};

export default Dashboard;
