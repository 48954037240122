import 'bootstrap/dist/css/bootstrap.min.css';
import './Donation.css';

import React, { useState } from 'react';
import DonationModal from '../../DonationModal';

const DonationForm = () => {
  const [selectedAmount, setSelectedAmount] = useState('100');
  const [donationType, setDonationType] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleAmountChange = (e) => {
    setSelectedAmount(e.target.value);
  };

  const handleDonateClick = (type) => {
    setDonationType(type);
    setShowModal(true); // Open the modal regardless of the amount selected
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="text-center">
        <h3>Make a Donation</h3>
        <form className="donation_form">
          <h4 className="mt-4">Select Amount:</h4>
          <div className="d-flex justify-content-center align-items-center gap-3">
            <div className="form-check mt-4">
              <input
                type="radio"
                id="blns_1"
                name="radio-group"
                value="100"
                checked={selectedAmount === '100'}
                onChange={handleAmountChange}
                className="form-check-input"
              />
              <label htmlFor="blns_1" className="form-check-label ms-2">
                ₹100
              </label>
            </div>
            <div className="form-check mt-4">
              <input
                type="radio"
                id="blns_2"
                name="radio-group"
                value="500"
                checked={selectedAmount === '500'}
                onChange={handleAmountChange}
                className="form-check-input"
              />
              <label htmlFor="blns_2" className="form-check-label ms-2">
                ₹500
              </label>
            </div>
            <div className="form-check mt-4">
              <input
                type="radio"
                id="Other"
                name="radio-group"
                value="Other"
                checked={selectedAmount === 'Other'}
                onChange={handleAmountChange}
                className="form-check-input"
              />
              <label htmlFor="Other" className="form-check-label ms-2">
                Other
              </label>
            </div>
          </div>
        </form>
        <button
          onClick={() => handleDonateClick('Donation')}
          className="btn btn-success mt-4 me-3"
        >
          Donation
        </button>
        <button
          onClick={() => handleDonateClick('Zakat')}
          className="btn btn-success mt-4"
        >
          Zakat
        </button>
      </div>

      {/* Donation/Zakat Modal */}
      <DonationModal
        show={showModal}
        onHide={handleModalClose}
        formType={donationType.toLowerCase()}
      />
    </div>
  );
};

export default DonationForm;
