import 'bootstrap/dist/css/bootstrap.min.css';

import Causeimg from '../../Component/latest cause.jpg';
import PopularCauses from '../../Pages/Popular/Popular';
import React from 'react';

const Causes = () => {
  return (
    <div>
      <div
        className="w-100 position-relative"
        style={{ height: '60vh', overflow: 'hidden' }}
      >
        <img
          src={Causeimg}
          alt="Cause"
          style={{
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
          }}
        />
        <div className="position-absolute top-50 start-50 translate-middle text-center text-white">
          {/* <h1 className="fs-1 fw-bold ">Latest Cause</h1> */}
        </div>
      </div>
      <div style={{ marginTop: '-30px' }}>
        {' '}
        {/* Adjust this value as needed */}
        <PopularCauses />
      </div>
    </div>
  );
};

export default Causes;
