import 'bootstrap/dist/css/bootstrap.min.css';
import './Popular.css';

import ComputerCourses from '../../Component/photos _15.jpg';
import { Link } from 'react-router-dom';
import React from 'react';
import Scholarships from '../../Component/photos _41.jpg';
import SchoolSupplies from '../../Component/photos _39.jpg';

const PopularCauses = () => {
  return (
    <div className="popular_causes_area section_padding">
      <div className="mt-4">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section_title text-center mb-55">
              <h3>
                <span>Popular Education Causes</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="single_cause">
              <div className="thumb">
                <img
                  src={ComputerCourses}
                  alt="Computer Courses"
                  className="cause_image"
                />
              </div>
              <div className="causes_content">
                <div className="balance d-flex justify-content-between align-items-center"></div>
                <h4>Computer Courses for Underprivileged Students</h4>
                <p>
                  Access to computer education is essential in the digital age.
                  We aim to provide computer courses and training to
                  underprivileged students, equipping them with valuable skills
                  for the future.
                </p>
                <Link className="read_more" to="/ComputerCourses">
                  Read More
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="single_cause">
              <div className="thumb">
                <img
                  src={Scholarships}
                  alt="Scholarships"
                  className="cause_image"
                />
              </div>
              <div className="causes_content">
                <div className="balance d-flex justify-content-between align-items-center"></div>
                <h4>Scholarships for Higher Education</h4>
                <p>
                  Many talented students struggle to afford higher education.
                  Our scholarship programs are designed to support deserving
                  students in pursuing their dreams of higher education.
                </p>
                <Link className="read_more" to="/Scholarships">
                  Read More
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="single_cause">
              <div className="thumb">
                <img
                  src={SchoolSupplies}
                  alt="School Supplies"
                  className="cause_image"
                />
              </div>
              <div className="causes_content">
                <div className="balance d-flex justify-content-between align-items-center"></div>
                <h4>Providing School Supplies</h4>
                <p>
                  Ensuring that students have the necessary supplies is crucial
                  for their success. We provide school supplies such as books,
                  stationery, and other essentials to students in need.
                </p>
                <Link className="read_more" to="/SchoolSupplies">
                  Read More
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularCauses;
