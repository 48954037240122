import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import qrCodeImage from '././Component/Zakat.jpg';

const DonationModal = ({ show, onHide, formType }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contact: '',
    comment: '',
    pan: '',
    address: '',
    amount: '',
  });
  const [showBarcode, setShowBarcode] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false); // Success Modal State

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = 'https://formsubmit.co/arifalikhan01986@gmail.com';
    const payload = { ...formData, formType };
  
    // Convert payload to FormData
    const formDataPayload = new FormData();
    for (const key in payload) {
      formDataPayload.append(key, payload[key]);
    }
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formDataPayload, // Send as form data, no Content-Type header needed
      });
  
      if (response.ok) {
        setShowSuccessModal(true);
        setShowBarcode(true);
        setShowOptions(true);
      } else {
        alert(`Failed to submit ${formType} form. Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('An error occurred while submitting the form. Please try again later.');
    }
  };

  const accountDetails =
    formType === 'donation'
      ? {
          title: 'Donation Account Details',
          name: 'PURVANCHAL DUSTAK',
          accountNo: '0184000100242026',
          ifsc: 'PUNB0018400',
          branch: 'Urdu Bazar, Gorakhpur',
        }
      : {
          title: 'Zakat Account Details',
          name: 'PURVANCHAL DUSTAK',
          accountNo: '03841131003761',
          ifsc: 'PUNB0038410',
          branch: 'Bank Road, Gorakhpur',
        };

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>{formType === 'donation' ? 'Donation Form' : 'Zakat Form'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="formContact">
              <Form.Label>Contact</Form.Label>
              <Form.Control type="tel" name="contact" value={formData.contact} onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="formPan">
              <Form.Label>PAN (optional for 80G benefit)</Form.Label>
              <Form.Control type="text" name="pan" value={formData.pan} onChange={handleChange} />
            </Form.Group>
            {formType === 'zakat' && (
              <Form.Group controlId="formAddress">
                <Form.Label>Address (optional)</Form.Label>
                <Form.Control type="text" name="address" value={formData.address} onChange={handleChange} />
              </Form.Group>
            )}
            <Form.Group controlId="formAmount">
              <Form.Label>Amount</Form.Label>
              <Form.Control type="number" name="amount" value={formData.amount} onChange={handleChange} required />
            </Form.Group>
            <Form.Group controlId="formComment">
              <Form.Label>Remarks</Form.Label>
              <Form.Control as="textarea" name="comment" value={formData.comment} onChange={handleChange} />
            </Form.Group>
            <div className="d-flex justify-content-center mt-3">
              <Button type="submit" variant="primary" className="btn-lg">
                Submit
              </Button>
            </div>
          </Form>

          {showOptions && (
            <div className="mt-4 text-center">
              <Button variant="success" onClick={() => setShowAccountModal(true)}>
                View Account Details
              </Button>

              {showBarcode && formType === 'donation' && (
                <div className="d-flex justify-content-center mt-4">
                  <h5>Click Here to View QR Code:</h5>
                </div>
              )}

              {formType === 'zakat' && (
                <div className="mt-3">
                  <h5>Click Here to View QR Code:</h5>
                  <img
                    src={qrCodeImage}
                    alt="QR Code"
                    style={{ width: '200px', height: '200px', cursor: 'pointer' }}
                    onClick={() => setShowQrModal(true)}
                  />
                </div>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* Success Modal */}
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)} centered>
        <Modal.Header closeButton style={{ backgroundColor: 'green', color: 'white' }}>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center" style={{ backgroundColor: 'white', color: 'black' }}>
          <h4>{formType} form submitted successfully!</h4>
        </Modal.Body>
      </Modal>

      {/* QR Code Modal */}
      <Modal show={showQrModal} onHide={() => setShowQrModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img src={qrCodeImage} alt="QR Code" style={{ width: '100%', maxWidth: '400px' }} />
        </Modal.Body>
      </Modal>

      {/* Account Details Modal */}
      <Modal show={showAccountModal} onHide={() => setShowAccountModal(false)} centered>
        <Modal.Header closeButton style={{ backgroundColor: 'green', color: 'white' }}>
          <Modal.Title>{accountDetails.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center', backgroundColor: 'white', color: 'black' }}>
          <p><strong>Account Name:</strong> {accountDetails.name}</p>
          <p><strong>Account No.:</strong> {accountDetails.accountNo}</p>
          <p><strong>IFSC Code:</strong> {accountDetails.ifsc}</p>
          <p><strong>Branch:</strong> {accountDetails.branch}</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DonationModal;
