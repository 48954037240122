import './PageStyles.css';

import React from 'react';
import largeImage from '../Component/photos _3.jpg';

const CCCCourse = () => {
  return (
    <div className="page">
      <div className="image-container">
        <img
          src={largeImage}
          alt="Course on Computer Concepts"
          className="img-fluid large_image"
        />
      </div>
      <div className="content">
        <h2>CCC Course Provided by Purvanchal Dastak</h2>
        <p>
          <b>Empower Your Future with Computer Literacy:</b> In today’s digital
          age, computer skills are essential for both personal and professional
          growth. Purvanchal Dastak is proud to offer the Course on Computer
          Concepts (CCC), a comprehensive program designed to equip individuals
          with fundamental computer skills and knowledge.
        </p>
        <h2>About the CCC Course</h2>
        <p>
          Our CCC course is designed to provide participants with a strong
          foundation in computer concepts and practical skills. Whether you're a
          beginner looking to enhance your digital literacy or someone seeking
          to formalize your computer knowledge, this course is tailored to meet
          your needs.
        </p>
        <h3>
          <b>Course Highlights:</b>
        </h3>
        <ul>
          <li>
            <b>Comprehensive Curriculum:</b> Covers essential topics including
            computer basics, operating systems, word processing, spreadsheets,
            and internet usage.
          </li>
          <li>
            <b>Experienced Trainers:</b> Learn from qualified instructors with
            extensive experience in computer education.
          </li>
          <li>
            <b>Hands-On Training:</b> Practical sessions and assignments to
            reinforce learning and ensure proficiency.
          </li>
          <li>
            <b>Certification:</b> Receive a recognized certification upon
            successful completion of the course, enhancing your credentials and
            employability.
          </li>
        </ul>

        <h3>
          <b>Program Features:</b>
        </h3>

        <h4>
          <b>Free Access:</b>
        </h4>
        <p>
          All training sessions, materials, and equipment are provided at no
          cost to participants. Open to individuals from various backgrounds,
          including those from economically disadvantaged communities.
        </p>

        <h4>
          <b>Flexible Scheduling:</b>
        </h4>
        <p>
          Classes are scheduled at various times, including evenings and
          weekends, to accommodate different schedules. Options for both
          in-person and online training sessions to reach a broader audience.
        </p>

        <h4>
          <b>Support and Mentorship:</b>
        </h4>
        <p>
          Access to experienced trainers and mentors who offer personalized
          guidance and support. Opportunities to connect with industry
          professionals for advice and networking.
        </p>

        <h4>
          <b>Certification:</b>
        </h4>
        <p>
          Participants receive a certificate upon successful completion of the
          program, which can be used to demonstrate skills to potential
          employers or clients.
        </p>

        <h4>
          <b>Community Building:</b>
        </h4>
        <p>
          Encouragement of group activities and networking opportunities to
          foster a supportive community of learners in the computer field.
        </p>

        <h3>
          <b>How to Enroll:</b>
        </h3>

        <h4>
          <b>Application Process:</b>
        </h4>
        <p>
          Interested individuals can apply through our website or local
          community centers. The application form requires basic information
          about the applicant’s background and interest in the program.
        </p>

        <h4>
          <b>Selection Criteria:</b>
        </h4>
        <p>
          The program is open to all individuals, with special consideration
          given to those from underprivileged backgrounds. No prior experience
          in computer skills is required.
        </p>

        <h4>
          <b>Orientation:</b>
        </h4>
        <p>
          An orientation session will introduce participants to the program,
          explain the curriculum, and outline expectations.
        </p>

        <h3>
          <b>Impact of the Program:</b>
        </h3>

        <h4>
          <b>Skill Development:</b>
        </h4>
        <p>
          Providing individuals with practical and marketable skills that can
          enhance their employability or enable them to pursue further education
          or careers.
        </p>

        <h4>
          <b>Economic Empowerment:</b>
        </h4>
        <p>
          Helping individuals gain valuable skills that can contribute to their
          financial independence.
        </p>

        <h4>
          <b>Community Enrichment:</b>
        </h4>
        <p>
          Creating a network of skilled individuals who can contribute to local
          economies and share their knowledge with others.
        </p>

        <h3>
          <b>How You Can Support:</b>
        </h3>

        <h4>
          <b>Volunteer:</b>
        </h4>
        <p>
          Offer your expertise by volunteering as a computer instructor or
          mentor in the program.
        </p>

        <h4>
          <b>Donate:</b>
        </h4>
        <p>
          Contribute funds or computer equipment and materials to support the
          training sessions.
        </p>

        <h4>
          <b>Partner with Us:</b>
        </h4>
        <p>
          Collaborate with local businesses or organizations to provide
          internships, job placements, or additional resources for participants.
        </p>
      </div>
    </div>
  );
};

export default CCCCourse;
