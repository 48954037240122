import './Volunteer.css';

import React, { useState } from 'react';

import Insta from '../../Component/instagram_3938052.png';
import facebookIcon from '../../Component/social_15707770.png';
import linkedinIcon from '../../Component/linkedin_1384046.png';
import twitterIcon from '../../Component/twitter-alt-circle_12107562.png';
import volunteer1 from '../../Component/photos _48.jpg';
import volunteer2 from '../../Component/photos .jpg';
import volunteer3 from '../../Component/photos _50.jpg';
import volunteer4 from '../../Component/photos _49.jpg';

const VolunteerSection = () => {
  const [expanded, setExpanded] = useState({});

  const volunteers = [
    {
      name: 'Mr Naim Akhtar Siddiqui',
      role: 'Founder & Chairman',
      image: volunteer1,
      description:
        ' A Fellow Member of the Institute of Chartered Accountants of India with over 25 years of experience in tax advisory, corporate financing, and investment banking.',
    },
    {
      name: 'Mr Mahmood Hasan Khan',
      role: 'Vice Chairman',
      image: volunteer2,
      description:
        'MBA (Finance), with over 20 years in corporate finance and fund syndication, instrumental in Goldrush Capital growth.',
    },
    {
      name: 'Mr Kalimul Haq',
      role: 'Treasurer',
      image: volunteer3,
      description:
        'Former area manager and entrepreneur with experience in various sectors including hospitality and education.',
    },
    {
      name: 'Mr Mohd Nisar Ansari',
      role: 'Secratary',
      image: volunteer4,
      description:
        'ICAI member with expertise in project financing, government audits, forensic audit, and tax consultancy.',
    },
  ];

  const socialLinks = [
    { icon: facebookIcon, alt: 'Facebook', link: '#' },
    { icon: Insta, alt: 'Instagram', link: '#' },
    { icon: linkedinIcon, alt: 'LinkedIn', link: '#' },
    { icon: twitterIcon, alt: 'Twitter', link: '#' },
  ];

  const handleReadMore = (index) => {
    setExpanded((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <div className="our_volunteer_area section_padding">
      <div className="Images">
        <div className="row justify-content-center">
          <div className="col-lg-6 text-center">
            <div className="section_title mb-55">
              <h3>
                <span>Our Founder Member</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          {volunteers.map((volunteer, index) => (
            <div className="col-lg-3 col-md-4 mb-4" key={index}>
              <div className="single_volunteer text-center">
                <div className="volunteer_thumb">
                  <img src={volunteer.image} alt={volunteer.name} />
                </div>
                <div className="volunteer_info">
                  <div className="social_links mb-3">
                    <ul className="list-unstyled d-flex justify-content-center p-0">
                      {socialLinks.map((link, idx) => (
                        <li className="mx-2" key={idx}>
                          <a href={link.link}>
                            <img
                              src={link.icon}
                              alt={link.alt}
                              className="social-icon"
                            />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="info_inner">
                    <h4>{volunteer.name}</h4>
                    <p>{volunteer.role}</p>
                    <p className="volunteer_description">
                      {volunteer.description}
                    </p>
                    {/* <p className="volunteer_description">
                      {expanded[index]
                        ? volunteer.description
                        : `${volunteer.description.slice(0, 100)}...`}
                      {volunteer.description.length > 100 && (
                        <button
                          className="read_more_btn"
                          onClick={() => handleReadMore(index)}
                        >
                          {expanded[index] ? 'Read Less' : 'Read More'}
                        </button>
                      )}
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VolunteerSection;
