import 'bootstrap/dist/css/bootstrap.min.css';
import './Activity.css';

import Activity from '../../Component/photos _13.jpg';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import DonationModal from '../../DonationModal';

const ImageAndContentRow = () => {
  const [showDonationModal, setShowDonationModal] = useState(false);
  const [formType, setFormType] = useState(''); // 'donation' or 'zakat'

  const handleOpenDonationModal = (type) => {
    setFormType(type);
    setShowDonationModal(true);
  };

  return (
    <div className="mt-4">
      <div className="row p-2">
        {/* Image section */}
        <div className="col-12 mb-3 d-flex justify-content-center">
          <img
            src={Activity}
            alt="Sample"
            className="img-fluid img-responsive"
          />
        </div>

        {/* Content section */}
        <div className="col-12 text-center">
          <h1 className="Latest">
            Provide uniform and stationary to underprivileged children through zakat.
          </h1>
          <p className="activity-text">
            Education is a fundamental right, but many underprivileged children
            are deprived of it due to financial constraints. Through Zakat, a
            powerful tool in Islam for social welfare, we can bridge this gap by
            providing essential resources such as uniforms, stationery, and
            directly paying school fees. This initiative not only supports their
            education but also empowers them to build a better future.
          </p>
        </div>

        <div className="col-12 d-flex flex-column flex-md-row justify-content-center mt-4">
          <Button as={Link} to="/Zakat" className="header-btn" style={{ marginRight: '10px' }}>
            Read More
          </Button>
          <Button onClick={() => handleOpenDonationModal('donation')} className="header-btn" style={{ marginRight: '10px' }}>
            Donation
          </Button>
          <Button onClick={() => handleOpenDonationModal('zakat')} className="header-btn">
            Zakat
          </Button>
        </div>
      </div>

      {/* Donation/Zakat Modal */}
      <DonationModal
        show={showDonationModal}
        onHide={() => setShowDonationModal(false)}
        formType={formType}
      />
    </div>
  );
};

export default ImageAndContentRow;
