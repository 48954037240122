import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import About from './Pages/About/About';
import CareerServices from './Pages/CareerServices/CareerServices';
import Causes from './Pages/Causes/Causes';
import Computer from './Helping/ComputerTraining';
import ContactPage from './Pages/Contact Us/Contactus';
import Dashboard from './Pages/Dashboard/Dashboard';
import Food from './Helping/Foodhelp';
import Footer from './Footer/Footer';
import Govenment from './Helping/CoachingGovernmentJob';
import Home from './Pages/Home/Home';
import LoginModal from './Pages/LoginModal/LoginModal';
import Medical from './Helping/Medicalcamp';
import Navbar from './Navbar/Navbar';
import PrivacyPolicy11 from './Pages/Privacy/Privacy';
import ProtectedRoute from './ProtectedRoute';
import ScrollToTop from './ScrollToTop';
import Social from './Pages/SocialEvent/SocialEvent';
import SocialEventDash from './Pages/SocialEventDash/SocialEventDash';
import Stiching from './Helping/StitchingWomen';
import Study from './Helping/Professionalstudy';
import Volunteers from './Pages/Volunteer1/Volunteers';
import Zakat from './Helping/Zakat';
import UpcomingEvents from './Pages/UpcomingEvents/UpcomingEvents';
import UpcomingEventDash from './Pages/UpcomingEventDash/UpcomingEventDash';
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  // Check if user is logged in on page load
  useEffect(() => {
    const storedLoginStatus = localStorage.getItem('isLoggedIn');
    if (storedLoginStatus === 'true') {
      setIsLoggedIn(true);
    }
  }, []);

  const handleShowLogin = () => setShowLogin(true);
  const handleCloseLogin = () => setShowLogin(false);

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <div className="app">
        <Navbar isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/program" element={<Causes />} />
          <Route path="/CareerServices" element={<CareerServices />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/Volunteers" element={<Volunteers />} />
          <Route path="/Privacy" element={<PrivacyPolicy11 />} />
          <Route path="/events" element={<Social />} />
          <Route path="/stitching-women" element={<Stiching />} />
          <Route path="/coaching-government-job" element={<Govenment />} />
          <Route path="/computer-training" element={<Computer />} />
          <Route path="/Zakat" element={<Zakat />} />
          <Route path="/ComputerCourses" element={<Food />} />
          <Route path="/Scholarships" element={<Medical />} />
          <Route path="/SchoolSupplies" element={<Study />} />
          <Route path="/UpcomingEvents" element={<UpcomingEvents />} />
          <Route path="/UpcomingEventDash" element={<UpcomingEventDash />} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute
                isLoggedIn={isLoggedIn}
                handleShowLogin={handleShowLogin}
              >
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/social-events"
            element={
              <ProtectedRoute
                isLoggedIn={isLoggedIn}
                handleShowLogin={handleShowLogin}
              >
                <SocialEventDash />
              </ProtectedRoute>
            }
          />
        </Routes>

        {/* Login Modal */}
        <LoginModal
          showLogin={showLogin}
          handleCloseLogin={handleCloseLogin}
          setIsLoggedIn={setIsLoggedIn}
        />
        <Footer />
      </div>
    </Router>
  );
};

export default App;
