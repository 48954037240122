import './PageStyles.css';

import React from 'react';
import SchoolSuppliesImage from '../Component/photos _39.jpg';

const SchoolSupplies = () => {
  return (
    <div className="page">
      <div className="image_container">
        <img
          src={SchoolSuppliesImage}
          alt="Providing School Supplies"
          className="img-fluid large_image"
        />
      </div>
      <div className="content">
        <h2>Providing School Supplies</h2>
        <p>
          Ensuring that students have access to essential school supplies is
          crucial for their academic success. Many students from underprivileged
          backgrounds lack the necessary tools to fully engage in their
          education. Our initiative aims to provide these students with books,
          stationery, and other essential supplies to support their learning
          journey.
        </p>
        <h3>Our Mission</h3>
        <p>
          Our mission is to bridge the gap in access to educational resources by
          providing school supplies to students in need. We strive to:
        </p>
        <ul>
          <li>
            Ensure that every student has the necessary tools to succeed in
            their studies.
          </li>
          <li>
            Reduce the financial burden on families struggling to provide for
            their children's educational needs.
          </li>
          <li>
            Promote equal educational opportunities for all students, regardless
            of their economic background.
          </li>
        </ul>

        <h3>Supplies We Provide</h3>
        <h4>Books:</h4>
        <ul>
          <li>Textbooks required for various subjects and grade levels.</li>
          <li>Reference books and supplementary reading materials.</li>
        </ul>

        <h4>Stationery:</h4>
        <ul>
          <li>
            Notebooks, pens, pencils, erasers, and other basic writing
            materials.
          </li>
          <li>
            Art supplies such as colored pencils, markers, and drawing paper.
          </li>
        </ul>

        <h4>Other Essentials:</h4>
        <ul>
          <li>School bags to carry books and supplies.</li>
          <li>Calculators, rulers, and other educational tools.</li>
        </ul>

        <h3>How We Operate</h3>
        <h4>Distribution Process:</h4>
        <ul>
          <li>
            We collaborate with local schools and community organizations to
            identify students in need of supplies.
          </li>
          <li>
            Supplies are distributed through school events, community centers,
            and direct outreach programs.
          </li>
        </ul>

        <h4>Partnerships and Collaborations:</h4>
        <ul>
          <li>
            We work with businesses, donors, and volunteers to source and
            distribute school supplies.
          </li>
          <li>
            Local schools and educational institutions help us reach the
            students who need the most assistance.
          </li>
        </ul>

        <h3>Who Can Benefit</h3>
        <ul>
          <li>
            <strong>Students from Low-Income Families:</strong> Children who
            lack the financial resources to afford necessary school supplies.
          </li>
          <li>
            <strong>Students in Remote and Rural Areas:</strong> Students living
            in areas where access to educational resources is limited.
          </li>
          <li>
            <strong>Disadvantaged Youth:</strong> Young individuals facing
            socio-economic challenges that impact their education.
          </li>
        </ul>

        <h3>Why This Program is Important</h3>
        <ul>
          <li>
            <strong>Enhancing Educational Outcomes:</strong> Providing students
            with the right tools improves their ability to learn and succeed
            academically.
          </li>
          <li>
            <strong>Reducing Educational Barriers:</strong> Access to supplies
            removes obstacles that can hinder a student's participation and
            performance in school.
          </li>
          <li>
            <strong>Promoting Equality:</strong> Ensuring all students have
            access to essential supplies helps level the playing field and
            supports educational equity.
          </li>
        </ul>

        <h3>How You Can Help</h3>
        <ul>
          <li>
            <strong>Donate:</strong> Contributions help us purchase and
            distribute school supplies to students in need.
          </li>
          <li>
            <strong>Sponsor a School Supplies Drive:</strong> Organize or
            sponsor a drive to collect supplies for distribution.
          </li>
          <li>
            <strong>Volunteer:</strong> Assist with organizing, packing, and
            distributing supplies to students and schools.
          </li>
        </ul>

        <h3>Contact Us</h3>
        <p>
          For more information about our school supplies program or to get
          involved, please contact us:
        </p>
        <p>
          Email:{' '}
          <a href="mailto:contact@purvanchaldastak.org">
            Purvanchaldastak1@gmail.com
          </a>
          <br />
          Phone: +91 8318730799, +91 8563058592
        </p>
      </div>
    </div>
  );
};

export default SchoolSupplies;
