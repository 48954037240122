import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import './Teammember.css';
const teamMembers = [
    {
      name: "John Doe",
      position: "React Developer",
      imageUrl: "https://via.placeholder.com/150",
      facebook: "#",
      instagram: "#",
      linkedin: "#",
      twitter: "#",
    },
    {
      name: "Jane Smith",
      position: "UI/UX Designer",
      imageUrl: "https://via.placeholder.com/150",
      facebook: "#",
      instagram: "#",
      linkedin: "#",
      twitter: "#",
    },
    {
      name: "Michael Brown",
      position: "Frontend Engineer",
      imageUrl: "https://via.placeholder.com/150",
      facebook: "#",
      instagram: "#",
      linkedin: "#",
      twitter: "#",
    },
    {
      name: "Emily Johnson",
      position: "Backend Developer",
      imageUrl: "https://via.placeholder.com/150",
      facebook: "#",
      instagram: "#",
      linkedin: "#",
      twitter: "#",
    },
    {
      name: "William Wilson",
      position: "Project Manager",
      imageUrl: "https://via.placeholder.com/150",
      facebook: "#",
      instagram: "#",
      linkedin: "#",
      twitter: "#",
    },
    {
      name: "Olivia Davis",
      position: "Graphic Designer",
      imageUrl: "https://via.placeholder.com/150",
      facebook: "#",
      instagram: "#",
      linkedin: "#",
      twitter: "#",
    },
    {
      name: "Liam Martinez",
      position: "QA Engineer",
      imageUrl: "https://via.placeholder.com/150",
      facebook: "#",
      instagram: "#",
      linkedin: "#",
      twitter: "#",
    },
    {
      name: "Sophia Garcia",
      position: "Product Designer",
      imageUrl: "https://via.placeholder.com/150",
      facebook: "#",
      instagram: "#",
      linkedin: "#",
      twitter: "#",
    },
  ];
  
function TeamMemberCard({ member }) {
  return (
    <div className="text-center p-5">
      <img src={member.imageUrl} alt={member.name} className="rounded-circle mb-3" width="120" height="120" />
      <h5>{member.name}</h5>
      <p>{member.position}</p>
      <div className="d-flex justify-content-center gap-5">
        <a href={member.facebook}><FaFacebook /></a>
        <a href={member.instagram}><FaInstagram /></a>
        <a href={member.linkedin}><FaLinkedin /></a>
        <a href={member.twitter}><FaTwitter /></a>
      </div>
    </div>
  );
}

function OurTeam() {
  const groupedMembers = [];
  for (let i = 0; i < teamMembers.length; i += 4) {
    groupedMembers.push(teamMembers.slice(i, i + 4));
  }

  return (
    <section className="container py-5">
    <h2 className="text-center mb-5" style={{ color: '#21D192' }}>
  Our Team
</h2>
      <Carousel interval={3000}>
        {groupedMembers.map((group, index) => (
          <Carousel.Item key={index}>
            <div className="d-flex justify-content-center">
              {group.map((member, i) => (
                <TeamMemberCard key={i} member={member} />
              ))}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
}

export default OurTeam;
