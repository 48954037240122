import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import axios from "axios";

// Function to group events into chunks of a given size
const chunkArray = (array, chunkSize) => {
  return array.reduce((chunks, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!chunks[chunkIndex]) chunks[chunkIndex] = [];
    chunks[chunkIndex].push(item);
    return chunks;
  }, []);
};

const SocialImages = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/events`);
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchEvents();
  }, []);

  const eventChunks = chunkArray(events, 3);

  return (
    <div className="container">
      <div className="text-center mb-4">
        <h4 style={{ color: "green" }}>Our Latest Events</h4>
      </div>

      {loading ? (
        <p className="text-center">Loading events...</p>
      ) : events.length > 0 ? (
        <Carousel interval={2000}>
          {eventChunks.map((chunk, index) => (
            <Carousel.Item key={index}>
              <div className="row justify-content-center">
                {chunk.map((event) => (
                  <div key={event.id} className="col-12 col-md-4">
                    <img
                      src={`${API_BASE_URL}${event.image}`}
                      alt={event.title}
                      className="img-fluid"
                      style={{
                        objectFit: "cover",
                        height: "400px",
                        width: "100%",
                        borderRadius: "10px",
                      }}
                    />
                    <h5 className="text-center mt-2">{event.title}</h5>
                  </div>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <p className="text-center text-danger">
          No events available right now.
        </p>
      )}
    </div>
  );
};

export default SocialImages;
