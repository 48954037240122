import './Privacy.css';

import React from 'react';
import privacyimg from '../../Component/Lock.jpg';

const PrivacyPolicy = () => {
  return (
    <div className="mt-5">
      <img
        src={privacyimg}
        alt="Privacy Policy Banner"
        className="img-fluid privacy-banner mb-4"
      />

      <div className=" privacy-content">
        <div className="privacy111">
          <h1>Privacy Policy</h1>
        </div>

        <h2 className="mt-4">Information Gathering</h2>
        <p>
          Purvanchal Dastak NGO collects information from users in several ways,
          for example:
        </p>
        <ul>
          <li>Makes a donation.</li>
          <li>Signs up for a campaign.</li>
          <li>Signs up to stay updated.</li>
        </ul>
        <p>
          While forwarding a donation for StepMind, the well-wishers must submit
          some personal information to help ensure genuine contributions:
        </p>

        <p>
          Purvanchal Dastak NGO does not collect or record the user’s personal
          information unless he/she chooses to provide it.
        </p>

        <h2 className="mt-4">Use of Personal Information</h2>
        <ul>
          <li>
            General browsing of the Purvanchal Dastak NGO website is anonymous,
            and it does not register the user’s personal information except for
            time, date, place of visits, and the internet service provider's
            name. This data is used only for statistics and diagnostics.
          </li>
          <li>
            By signing up for Purvanchal Dastak NGO services, the user
            authorizes StepMind to collect information based on usage. This
            information is used to provide a better experience and is handled
            per user instructions.
          </li>
          <li>
            Purvanchal Dastak NGO keeps user information strictly confidential
            and secured. It is handled only by authorized personnel and is never
            shared with third parties.
          </li>
          <li>
            Information is used to:
            <ul>
              <li>Keep an accurate record of donations received.</li>
              <li>Update users through bulletins and newsletters.</li>
              <li>Provide relevant information to users.</li>
              <li>
                Learn more about site visitors, donors, and campaign
                participants.
              </li>
            </ul>
          </li>
          <li>
            Purvanchal Dastak NGO does not store user data unless requested by
            the user, who can request deletion at any time.
          </li>
        </ul>

        <h2 className="mt-4">Privacy of E-mail Lists</h2>
        <p>
          Individuals who join Purvanchal Dastak NGO mailing lists are added to
          its email database. Purvanchal Dastak NGO does not sell, rent, or
          share these addresses with third parties.
        </p>

        <h2 className="mt-4">Cookie Policy</h2>
        <ul>
          <li>
            Cookies are electronic information sent by Purvanchal Dastak NGO
            that helps recognize users on future visits.
          </li>
          <li>
            Users can adjust their browser settings to control cookie behavior.
          </li>
          <li>
            Disabling cookies may affect the functionality of the Purvanchal
            Dastak NGO website.
          </li>
        </ul>

        <h2 className="mt-4">Payment Gateway</h2>
        <ul>
          <li>
            Purvanchal Dastak NGO uses secure technology for payments, including
            SSL connections and encryption.
          </li>
        </ul>

        <h2 className="mt-4">External Web Services</h2>
        <ul>
          <li>
            Purvanchal Dastak NGO uses external services like YouTube for
            content. These services may collect information on content
            consumption.
          </li>
          <li>
            Purvanchal Dastak NGO is not responsible for the privacy policies of
            linked sites.
          </li>
        </ul>

        <h2 className="mt-4">Changes to Privacy Policy</h2>
        <p>
          Purvanchal Dastak NGO may update its privacy policy as needed and will
          notify users accordingly. If users have any questions, they can
          contact Purvanchal Dastak NGO at: [example@hotmail.com].
        </p>

        <h2 className="mt-4">Refund and Cancellation Policy</h2>
        <ul>
          <li>No refund/cancellation of donations is allowed.</li>
          <li>No cash or money refunds will be made.</li>
          <li>
            In-kind donations will be directed to the poorest communities.
          </li>
          <li>
            All donations will be used for community development, children's
            education, or women's empowerment.
          </li>
        </ul>

        <h2 className="mt-4">Terms and Conditions</h2>
        <p>
          Use of this site is subject to Purvanchal Dastak NGO terms and
          conditions, including adherence to lawful use and respect for
          third-party rights. Purvanchal Dastak NGO reserves the right to change
          these terms without notice. All content on the site is provided "as
          is" and Purvanchal Dastak NGO is not liable for any damages arising
          from its use. Commercial use or publication of site content is
          prohibited without prior authorization from Purvanchal Dastak NGO.
          Links to third-party websites are provided for convenience, and
          Purvanchal Dastak NGO is not responsible for their content.
        </p>

        <p>
          For questions, you can reach StepMind at [Address]. Phone: [Number].
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
