import './Footer.css'; // Custom styles for the footer

import FaFacebook from '../Component/social_15707770.png';
import FaInstagram from '../Component/instagram_3938052.png';
import FaLinkedin from '../Component/linkedin_1384046.png';
import FaTwitter from '../Component/twitter-alt-circle_12107562.png';
import { Link } from 'react-router-dom';
import React from 'react';
import news1 from '../Component/photos _15.jpg';
import news2 from '../Component/photos _41.jpg';

const Footer = () => {
  return (
    <footer className="footer mt-5">
      <section
        className="d-flex justify-content-between p-4 text-white"
        style={{ backgroundColor: '#21D192' }}
      >
        <div className="me-5">
          <span>Get connected with us on social networks:</span>
        </div>
        <div className="social_links">
          <a
            href="https://www.facebook.com/PurvanchalDustak?mibextid=kFxxJD"
            className="text-white me-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={FaFacebook}
              alt="Facebook"
              style={{ width: 24, height: 24 }}
            />
          </a>
          <a
            href="https://twitter.com"
            className="text-white me-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={FaTwitter}
              alt="Twitter"
              style={{ width: 24, height: 24 }}
            />
          </a>
          <a
            href="https://instagram.com"
            className="text-white me-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={FaInstagram}
              alt="Instagram"
              style={{ width: 24, height: 24 }}
            />
          </a>
          <a
            href="https://linkedin.com"
            className="text-white me-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={FaLinkedin}
              alt="LinkedIn"
              style={{ width: 24, height: 24 }}
            />
          </a>
        </div>
      </section>
      {/* Main content section */}
      <div className="footer_top" style={{ backgroundColor: '#ECEFF1' }}>
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3">
            {/* About the Company */}
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold">Purvanchal Dastak </h6>
              <hr
                style={{
                  width: '60px',
                  backgroundColor: '#7c4dff',
                  height: '2px',
                }}
              />
              <p>
                At Purvanchal Dastak NGO, our mission is to uplift communities
                and empower individuals in the Purvanchal region. We are
                committed to addressing critical needs through our various
                programs and services.
              </p>
            </div>

            {/* Services */}
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold">Services</h6>
              <hr
                style={{
                  width: '60px',
                  backgroundColor: '#7c4dff',
                  height: '2px',
                }}
              />
              <ul className="list-unstyled">
                <li>
                  <Link to="/About" className="text-dark">
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/program" className="text-dark">
                    Latest Cause
                  </Link>
                </li>
                <li>
                  <Link to="/events" className="text-dark">
                    Social Event
                  </Link>
                </li>
                <li>
                  <Link to="/Volunteers" className="text-dark">
                    Founder Member
                  </Link>
                </li>
                <li>
                  <Link to="/Contact" className="text-dark">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/CareerServices" className="text-dark">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/Privacy" className="text-dark">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>

            {/* Contacts */}
            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold">Contacts</h6>
              <hr
                style={{
                  width: '60px',
                  backgroundColor: '#7c4dff',
                  height: '2px',
                }}
              />
              <p>+91 8318730799, +91 8563058592</p>
              <p>Purvanchaldastak1@gmail.com</p>
              <p>Chotey Qazipur, Near Old LIU Office, Gorakhpur, U.P.</p>
            </div>

            {/* Top News */}
            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold">Top News</h6>
              <hr
                style={{
                  width: '60px',
                  backgroundColor: '#7c4dff',
                  height: '2px',
                }}
              />
              <ul className="list-unstyled">
                <li className="d-flex align-items-center mb-3">
                  <img
                    src={news1}
                    alt="News 1"
                    style={{ width: '50px', marginRight: '10px' }}
                  />
                  <Link to="#">
                    <h6>Computer Courses for Underprivileged Students</h6>
                  </Link>
                </li>
                <li className="d-flex align-items-center">
                  <img
                    src={news2}
                    alt="News 2"
                    style={{ width: '50px', marginRight: '10px' }}
                  />
                  <Link to="#">
                    <h6>Scholarships for Higher Education</h6>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Copyright section */}
      <div
        className="text-center p-3"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
      >
        © {new Date().getFullYear()} All rights reserved |{' '}
        <Link to="#">Your Link</Link>
      </div>
    </footer>
  );
};

export default Footer;
