import './Profile.css';
import Charity from '../../Component/photos _33.jpg';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import DonationModal from '../../DonationModal';

const Slider = () => {
  const [showDonationModal, setShowDonationModal] = useState(false);
  const [formType, setFormType] = useState(''); // 'donation' or 'zakat'

  const handleOpenDonationModal = (type) => {
    setFormType(type);
    setShowDonationModal(true);
  };

  return (
    <div className="col-lg-12">
      <div className="single_slider">
        <div className="slider_content">
          <span style={{ fontSize: '2rem' }}>Welcome to Purvanchal Dastak</span>
          <h3 style={{ fontSize: '2rem', color: 'White' }}>
            Help the children in Need
          </h3>

          <button
            onClick={() => handleOpenDonationModal('donation')}
            className="btn btn-primary"
            style={{ marginRight: '10px' }}
          >
            Donation
          </button>

          <button
            onClick={() => handleOpenDonationModal('zakat')}
            className="btn btn-primary"
          >
            Zakat
          </button>
        </div>
      </div>

      {/* Donation/Zakat Modal */}
      <DonationModal
        show={showDonationModal}
        onHide={() => setShowDonationModal(false)}
        formType={formType}
      />
    </div>
  );
};

export default Slider;
