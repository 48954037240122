import { Button, Form, Modal } from 'react-bootstrap';
import React from 'react';
import axios from 'axios';
import logo from '../../Component/Purvanchal Dastak Logo.jpg';
import { useNavigate } from 'react-router-dom';

const LoginModal = ({ showLogin, handleCloseLogin, setIsLoggedIn }) => {
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const email = e.target.formBasicEmail.value;
    const password = e.target.formBasicPassword.value;
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/login`, {
        email,
        password,
      });
  
      if (response.status === 200) {
        localStorage.setItem('isLoggedIn', 'true');
        setIsLoggedIn(true);
        handleCloseLogin();
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert(error.response?.data?.message || 'Login failed');
    }
  };
  

  return (
    <Modal show={showLogin} onHide={handleCloseLogin}>
      <Modal.Header closeButton>
        <img
          src={logo}
          alt="Logo"
          style={{ width: '100px', marginRight: '10px' }}
        />
        <h5 className="mb-0">Purvanchal Dastak Admin Page</h5>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleLogin}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="formBasicEmail"
              placeholder="Enter email"
              required
            />
          </Form.Group>
          <Form.Group controlId="formBasicPassword" className="mt-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              name="formBasicPassword"
              placeholder="Password"
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">
            Login
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
